import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ClientsWorkingPointsDataInterface } from "../../../../redux/interfaces/expedition.interface";

export const clientsWorkingPointsTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id punct lucru",
    minW: 125,
  },
  {
    label: "Localitate",
    value: "localitate",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Judet",
    value: "judet",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Adresa",
    value: "adresa",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Persoana contact",
    minW: 125,
  },
  {
    value: "Telefon",
    minW: 125,
  },
  {
    value: "deliveryPoint",
    label: "Punct de livrare/asociat",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Status",
    minW: 125,
  },
];

export const getClientsWorkingPointsTableData: any = (
  data: ClientsWorkingPointsDataInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.idPctLucru,
      crudData: item,
      data: [
        {
          title: item.idPctLucru,
        },
        {
          title: item.localitate,
        },
        {
          title: item.judet,
        },
        {
          title: item.adresa,
        },
        {
          title: item.persContact,
        },
        {
          title: item.telContact,
        },
        {
          badges: [
            {
              badgeText: item.pctDeLivrare,
              badgeColor: "light-info",
            },
            {
              badgeText: item.pctDeLivrareAsociat ?? "-",
              badgeColor: "light-success",
            },
          ],
        },

        {
          badges: [
            {
              badgeText: item.status ? "Validat" : "Nevalidat",
              badgeColor: item.status ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
