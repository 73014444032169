import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { expeditionRoutes } from "../../../../../router/routesConstants";
import { useAppDispatch } from "../../../../../redux/hooks";
import usePermissions from "../../../../../router/usePermissions";
import userRoles from "../../../../../constants/userRoles";
import { ClientsDataInterface } from "../../../../../redux/interfaces/expedition.interface";
import { setExpeditionClientData } from "../../../../../redux/reducer/expedition.reducer";

function ClientsActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: ClientsDataInterface;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();

  const { CLIENTS_WORKING_POINTS_PERMISSION } = {
    CLIENTS_WORKING_POINTS_PERMISSION: isAuthorized(
      userRoles.CLIENTS_WORKING_POINTS,
    ),
  };

  const handleGoToClientWorkingPoints = () => {
    dispatch(setExpeditionClientData(crudData));
    navigate(
      `${itemId}/${expeditionRoutes.clientsWorkingPoints}?denumireClient=${crudData.denumireClient}`,
    );
  };

  return (
    <div>
      {CLIENTS_WORKING_POINTS_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Puncte de lucru"
          onClick={handleGoToClientWorkingPoints}>
          <SvgIcon type="WORKING_POINT" />
        </CustomButton>
      )}
    </div>
  );
}

export default ClientsActionButton;
