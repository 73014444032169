import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import { AddAllDaysItemScheduleInterface } from "../../pages/Expedition/components/ConfigWorkingDays";

import { ScheduleWorkingRoutesListInterface } from "../interfaces/expedition.interface";
import { DropdownValue } from "../../components/misc/Dropdown";

export const getScheduleWorkingPoints = createAsyncThunk(
  "/expedition/scheduleWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      deliveryPoint: string;
      route?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, deliveryPoint, route } = params;

    try {
      const { data } = await apiRequest(
        `/nomPuncteLucru?page=${page}&perPage=${perPage}&searchPctLucru=${
          deliveryPoint ?? ""
        }&searchRuta=${route ?? ""}`,
        "GET",
      );
      console.log("data", data);
      return data;
    } catch (err: any) {
      console.warn("/expedition/scheduleWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingPointsDetails = createAsyncThunk(
  "/expedition/getScheduleWorkingPointsDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectPctLucru/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingPointsDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateWorkingPoint = createAsyncThunk(
  "/expedition/postCreateOrUpdateWorkingPoint",
  async (
    params: {
      activeWorkingDays: AddAllDaysItemScheduleInterface[];
      id: number;
      selectedWorkingRoute: ScheduleWorkingRoutesListInterface;
      adresa: string;
      denumire: string;
      localitate: string;
      judet: string;
      cod_postal: string;
      pers_contact: string;
      tel_contact: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdatePctLucru",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/postCreateOrUpdateWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutes = createAsyncThunk(
  "/expedition/getWorkingRoutes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage } = params;

    try {
      const { data } = await apiRequest(
        `/nomRute?page=${page}&perPage=${perPage}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesDetails = createAsyncThunk(
  "/expedition/getWorkingRoutesDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectRuta/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleRoutes = createAsyncThunk(
  "/expedition/postScheduleRoutes",
  async (
    params: {
      activeWorkingDays: AddAllDaysItemScheduleInterface[];
      internode: boolean;
      id: number;
      selectedNode: DropdownValue;
      name: string;
      code: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/createOrUpdateRuta", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/postScheduleRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesNodes = createAsyncThunk(
  "/expedition/getWorkingRoutesNodes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNoduri", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditNodeDetails = createAsyncThunk(
  "/expedition/postEditNodeDetails",
  async (
    {
      callBack,
      params,
    }: {
      callBack?: () => void;
      params: {
        nodId: number;
        auto: string;
        sofer: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/editNod", "POST", params);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/postEditNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getClientsWorkingPoints = createAsyncThunk(
  "/expedition/clientsWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      clientId: number | string;
      deliveryPoint: string;
      localitate: string;
      judet: string;
      adresa: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const {
      page,
      perPage,
      deliveryPoint,
      clientId,
      localitate,
      judet,
      adresa,
    } = params;

    try {
      const { data } = await apiRequest(
        `/showClientsWorkingPoints?clientId=${clientId}?page=${page}&perPage=${perPage}&searchLocalitate=${localitate}&searchJudet=${judet}&searchAdresa=${adresa}&searchPctLucru=${deliveryPoint}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/clientsWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getClients = createAsyncThunk(
  "/expedition/getClients",
  async (
    params: {
      page?: number;
      perPage?: number;
      denumireClient: string;
      localitate: string;
      judet: string;
      adresa: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, denumireClient, localitate, judet, adresa } =
        params;

      const { data } = await apiRequest(
        `/getClients?page=${page}&perPage=${perPage}&searchClient=${denumireClient}&searchLocalitate=${localitate}&searchJudet=${judet}&searchAdresa=${adresa}`,
        "GET",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getClients", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUpdateWorkingPoint = createAsyncThunk(
  "/expedition/fetchUpdateWorkingPoint",
  async (
    params: {
      page?: number;
      perPage?: number;
      clientId: number | string;
      deliveryPoint: string;
      localitate: string;
      judet: string;
      adresa: string;
      idPctLucru: number;
      idPctLucruSelectat: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, clientId } = params;

    try {
      const { data } = await apiRequest(`/updateWorkingPoint`, "POST", params);
      dispatch(
        getClientsWorkingPoints({
          clientId: clientId,
          page: page,
          perPage: perPage,
          deliveryPoint: "",
          adresa: "",
          localitate: "",
          judet: "",
        }),
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchUpdateWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
