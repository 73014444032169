import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import {
  fetchAllFleetDocs,
  fetchShowAllDocTypes,
} from "../../../redux/api/fleet.api";

import {
  fleetAllDocumentsTableHeader,
  getFleetAllDocumentsTableData,
} from "./components/tableConfigs/fleetAllDocumentsTableConfig";

import TableWidget from "../../../components/tables/TableWidget";
import Text from "../../../styleguide/Text";
import FleetAllDocumentsActionButton from "./components/TableActionButtonsComponents/FleetAllDocumentsActionButton";
import { clearFleetTypeData } from "../../../redux/reducer/fleet.reducer";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

function FleetAllDocumets({ withCarId }: { withCarId?: boolean }) {
  const dispatch = useAppDispatch();

  const { routeMainParam, routeParams } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };
  const { valability } = routeParams as { valability: string };

  const {
    fleet: {
      fleetAllDocuments: {
        data: { data: fleetAllDocumentsData, total },
        isLoading: isLoading,
      },
      fleetTypeDataAllDocs: {
        data: fleetTypeDataAllDocs,
        isLoading: fleetTypesLoading,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  useEffect(() => {
    dispatch(fetchShowAllDocTypes());
    return () => {
      dispatch(clearFleetTypeData());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      valability &&
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter
    ) {
      dispatch(
        fetchAllFleetDocs({
          idAuto: withCarId ? carId : null,
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          valability: valability,
          documentType: action.tableFilter.documentType,
        }),
      );
    }
  };

  const fleetAllDocumentsTableHeaderNew = fleetAllDocumentsTableHeader.map(
    (item) => {
      if (!fleetTypesLoading && item.value === "documentType") {
        const fleetTypesDropdown = fleetTypeDataAllDocs?.map(
          ({ label, id }) => ({
            label,
            value: id,
          }),
        );
        return {
          withFilter: [{ label: "Toate", value: 0 }, ...fleetTypesDropdown],
          ...item,
        };
      }
      return item;
    },
  );

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <div className="d-flex flex-column">
          <Text
            variant="h3"
            component="h3"
            className="card-title mb-4"
            gutterBottom>
            {carId ? `Documente masina ${nrauto}` : "Documente masini"}
          </Text>
        </div>
      )}
      tableHeaderData={fleetAllDocumentsTableHeaderNew}
      tableItemsData={getFleetAllDocumentsTableData(fleetAllDocumentsData)}
      handleTableActions={handleTableActions}
      borderedRow
      tableLoading={isLoading || fleetTypesLoading}
      ActionButtonsComponent={(props: any) => (
        <FleetAllDocumentsActionButton {...props} />
      )}
      totalItems={total}
    />
  );
}

export default FleetAllDocumets;
